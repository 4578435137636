import React from 'react'
import { Link } from 'react-scroll'
import { Container, Col, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import bannerImg from '../assets/images/direct-patient-care.jpg'

class WellnessBanner extends React.Component {
    constructor(props) {
        super(props);
        this.title = this.props.title
        this.className = this.props.className
        this.titleColor = this.props.titleColor
        this.subTitle = this.props.subTitle
        this.subTitleColor = this.props.subTitleColor
        this.image = this.props.image || bannerImg
    }
    
    render() {
        return (
            <section id='banner' className={`${this.className}`} style={{background: `url(${this.image}) top center no-repeat`, backgroundSize: `cover`}}>
                <Container>
                    <Row>
                        <Col xs='12' sm='12' md='12' lg='12'>
                            <h1 style={{color: `${this.titleColor}`}}>{this.title}</h1>
                            <h3 style={{color: `${this.subTitleColor}`}}>{this.subTitle}</h3>
                        </Col>
                        <Col className='icon-container display-flex' xs='12' sm='12' md='12' lg='12'>
                            <Col xs='12' sm='6' md='6' lg='6'>
                                <Link to='scrollHeart' activeClass='active' duration={700}>
                                    <FontAwesomeIcon className='icon' icon='heartbeat' />
                                    <div className='arrow bounce'>
                                        <p><strong className='blueColor'>Hormone Replacement <br />Therapy</strong></p>
                                        <FontAwesomeIcon className='arrowBtn' icon='chevron-down' />
                                    </div>
                                </Link>
                            </Col>
                            <Col xs='12' sm='6' md='6' lg='6'>
                                <Link to='scrollWeight' activeClass='active' duration={700}>
                                    <FontAwesomeIcon className='icon' icon='weight' />
                                    <div className='arrow bounce'>
                                        <p><strong className='blueColor'>Medically Supervised <br />Weight Loss</strong></p>
                                        <FontAwesomeIcon className='arrowBtn' icon='chevron-down' />
                                    </div>
                                </Link>
                            </Col>
                        </Col>
                    </Row>
                </Container>
            </section>
        )
    }
}

export default WellnessBanner
