import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Container, Row, Col } from 'react-bootstrap'
import Layout from '../components/layout'
import { ContactForm, Name, Email } from '../components/Forms/ContactForms'
import NumberFormat from 'react-number-format'
import ReCAPTCHA from 'react-google-recaptcha'
import WellnessBanner from '../components/wellnessBanner'
import Seo from '../components/seo'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import WellnessSeoImage from '../assets/images/wellness.jpg'

const recaptchaRef = React.createRef()

class Wellness extends React.Component {
    constructor(props) {
        super(props)

        this.handleUpdate = this.handleUpdate.bind(this)

        this.state = {
            name: ``,
            email: ``,
            phone_number: ``,
            website: ``,
            error: ``,
            loading: false,
        }
    }
    
    handleUpdate = event => {
        if (event.target.name === 'name') {
            this.setState({
                [event.target.name]: event.target.value,
                error: '',
            })
        }
        if (event.target.name === 'email') {
            this.setState({
                [event.target.name]: event.target.value,
                error: '',
            })
        }
        if (event.target.name === 'website') {
            this.setState({
                [event.target.name]: event.target.value,
                error: '',
            })
        }
        if (event.target.name === 'phone_number') {
            this.setState({
                [event.target.name]: event.target.value.replace(/\D/g, ''),
                error: '',
            })
        }
        this.setState({
            [event.target.name]: event.target.value,
            error: '',
        })
    }
    
    onChange(value) {
        console.log('Captcha Value:', value);
    }

    render() {
        return (
            <StaticQuery
            query={graphql`
                query WellnessMeta {
                    site {
                        siteMetadata {
                            title
                            description
                            author
                            emailUrl
                            phone
                            google {
                                googleMapAPIKey
                                googleReCaptchaKey
                            }
                        }
                    }
                }
            `}
            render={data => (
                <>
                <Layout page='wellness'>
                    <Seo title={`Additional Wellness & Life Services | ${data.site.siteMetadata.title}`} description={`Explore our additional services provided through our care.`} image={WellnessSeoImage} />
                    <WellnessBanner className={`wellness`} title={`Additional Wellness & Life Services`} image={WellnessSeoImage} />
                    <section id='hormone' className='scrollHeart'>
                        <Container fluid>
                            <Row fluid>
                                <Col className='image' xs='12' sm='12' md='6' lg='6'></Col>
                                <Col className='content' xs='12' sm='12' md='6' lg='6'>
                                    <h2>Hormone Replacement Therapy</h2>
                                    <p>As we age, the function of our reproductive organs can decline. This results in decreased levels of powerful hormones that govern many of the functions of our bodies. Symptoms like fatigue, weight gain, inability to lose weight, inability to gain muscle, loss of libido and sexual dysfunction, poor sleep and mood changes, are just some of the many symptoms that can be attributed to low testosterone. As a member of the Androgen Society, Dr. Sambataro stays up to date on the latest research in the growing field of hormone replacement therapy. If you are experiencing any of these symptoms, even if you are on the younger side, please reach out to us and obtain a free consultation!</p>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                    <section id='weight' className='scrollWeight'>
                        <Container fluid>
                            <Row fluid>
                                <Col className='content' xs='12' sm='12' md='6' lg='6'>
                                    <h2>Medically Supervised Weight Loss</h2>
                                    <p>While calories "in" certainly dose equal calories "out," metabolism and weight loss are a complex issue. Patients with excess body fat are at risk for many diseases including diabetes, congestive heart failure, hypertension, stroke and heart attack. "Eating right" and "going to the gym" usually aren't sufficient for effective, long lasting weight loss. Medications in the right setting, combined with dietary and nutritional counseling along with exercise can result in successful long lasting weight loss.</p>
                                </Col>
                                <Col className='image' xs='12' sm='12' md='6' lg='6'></Col>
                            </Row>
                        </Container>
                    </section>
                    <section id='contact' className='lightBlueColorBkgd'>
                        <Container>
                            <Row>
                                <Col xs='12' sm='12' md='5' lg='5'>
                                    <div className='spacer40 hidden-xs hidden-sm'></div>
                                    <h2 class='whiteColor'>Reach out today!</h2>
                                    <div className='spacer20'></div>
                                    <p class='whiteColor'><strong>{data.site.siteMetadata.title}</strong><br /><a className='reverse' href={`mailto:${data.site.siteMetadata.emailUrl}`}>{data.site.siteMetadata.emailUrl}</a><br />{data.site.siteMetadata.phone}</p>
                                </Col>
                                <Col xs='12' sm='12' md='7' lg='7'>
                                    <ContactForm title={`Contact Us`} subtitle={``} error={this.state.error} reverse={true}>
                                        <Name handleUpdate={this.handleUpdate} email={this.state.email} autoComplete='on' />
                                        <Email handleUpdate={this.handleUpdate} email={this.state.email} autoComplete='on' />
                                        <fieldset>
                                            <span className='label'>Phone:</span>
                                            <NumberFormat id='phone_number' placeholder='+1 (###) ###-####' onChange={this.handleUpdate} name='phone_number' value={this.state.phone_number} type='tel' format='+1##########' mask='_' />
                                        </fieldset>
                                        <fieldset className='required'>
                                            <textarea id='message' name='message' aria-label='message' placeholder='Type your message here...' required></textarea>
                                        </fieldset>
                                        <ReCAPTCHA ref={recaptchaRef} sitekey={data.site.siteMetadata.google.googleReCaptchaKey} onChange={this.onChange} />
                                        <div className='spacer20'></div>
                                        <button type='submit' className='btn btn-secondary btn-block' disabled={this.state.loading}>
                                            {this.state.loading ? null : 'Send'}
                                            {this.state.loading && (
                                                <span className='spinner-border spinner-border-sm' role='status' aria-hidden='true' />
                                            )}
                                            {this.state.loading ? null : (<FontAwesomeIcon icon="chevron-right" />)}
                                        </button>
                                    </ContactForm>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                </Layout>
                </>
            )}
            />
        )
    }
}

export default Wellness